<template>
	<div>
		<el-dialog :close-on-click-modal="false" :title="titleName" v-if="visible" :visible.sync="visible"
			width="1250px" top="10vh" :append-to-body="true" :before-close="noClick">
			<el-row type="flex">
				<el-col>
					<div class="watchText">应诊编号：</div>
					<div class="watchText">{{seeInfo.id}}</div>
				</el-col>
				<el-col>
					<div class="watchText">患者姓名：</div>
					<div class="watchText">{{seeInfo.see_name}}</div>
				</el-col>
				<el-col>
					<div class="watchText">应诊时间：</div>
					<div class="watchText">{{seeInfo.seePatTime}}</div>
				</el-col>
			</el-row>
			<el-row type="flex">
				<el-col>
					<div class="watchText">性别：</div>
					<div class="watchText">{{seeInfo.see_sex}}</div>
				</el-col>
				<el-col>
					<div class="watchText">年龄：</div>
					<div class="watchText">{{seeInfo.see_age}}</div>
				</el-col>
				<el-col>
					<div class="watchText"></div>
					<div class="watchText"></div>
				</el-col>
			</el-row>
			<el-row type="flex">

				<el-col>
					<div class="watchText">医生编号：</div>
					<div class="watchText">{{seeInfo.doctorId}}</div>
				</el-col>
				<el-col>
					<div class="watchText">医生姓名：</div>
					<div class="watchText">{{seeInfo.doctorName}}</div>
				</el-col>
				<el-col>
					<div class="watchText"></div>
					<div class="watchText"></div>
				</el-col>
			</el-row>
			<el-row type="flex">
				<el-col>
					<div class="watchText">中医证型：</div>
					<div class="watchText" v-html="seeInfo.result"></div>
				</el-col>
			</el-row>
			<el-row type="flex">
				<el-col>
					<div class="watchText">症状描述：</div>
					<div class="watchTextLong" v-html="seeInfo.result_detail"></div>
				</el-col>
			</el-row>
			<!-- <el-row type="flex">
                <el-col>
                    <div class="watchText">医嘱：</div>
                    <div class="watchTextLong" v-html="openRecipes[0].lookOut"></div>
	            </el-col>
	        </el-row> -->
			<el-row type="flex">
				<el-col>
					<div class="watchText">照片：</div>
					<el-image style="width: 100px; height: 100px" v-for="(url,i) in seeInfo.imgUrls" :key="i" :src="url"
						:preview-src-list="seeInfo.imgUrls"></el-image>
				</el-col>
			</el-row>
			<!-- 处方详情 -->
			<el-card class="mt30">
				<div v-for="(openRecipe,i) in openRecipes" :key="i">
					<el-row type="flex">
						<el-col>
							<div class="watchTableKey">处方名称：</div>
							<div class="watchTableValue">{{openRecipe.recipeName}}</div>
						</el-col>
						<el-col>
							<div class="watchTableKey">剂数：</div>
							<div class="watchTableValue">{{openRecipe.dosage}}</div>
						</el-col>

						<el-col>
							<el-row>
								<el-col :span="12"v-for="(item,index) in openRecipe.recipseDetail" :key="index"  style="float: left;">
										<div v-if="item.price_type=='1'" style="float: left;line-height: 35px;">{{item.drugName}}：{{item.totalPrice}}元</div>
								</el-col>
							</el-row>
						</el-col>

						<el-col>
							<div class="watchTableKey">医嘱：</div>
							<div class="watchTableValue">{{openRecipe.lookOut}}</div>
						</el-col>
						<!--<el-col>
                        <div class="watchTableKey">服用方式：</div>
                        <div class="watchTableValue">{{openRecipe.takeRule}}</div>
                    </el-col>-->
					</el-row>
					<el-table :data="openRecipe.recipseDetail.filter((v) => v.price_type=='0')" border
						:summary-method="getSummaries" show-summary style="width: 100%">
						<el-table-column prop="drugType" label="药品类型"> </el-table-column>
						<el-table-column prop="drugName" label="药品名称"> </el-table-column>
						<el-table-column prop="unit" label="药品单位"> </el-table-column>
						<el-table-column prop="quantity" sortable label="数量(克)"> </el-table-column>
						<el-table-column prop="totalPrice" sortable label="金额(元)"> </el-table-column>
					</el-table>
				</div>
				<el-row type="flex">
					<el-col>
						<div class="box-b"> 快递运费：12.00元 总计： 处方金额：{{seeInfo.drug_sum}}元 ，
							总计：{{seeInfo.orderSumTotal}}元 </div>
					</el-col>
				</el-row>
			</el-card>
			<!--  订单详情  -->
			<el-card class="mt30" v-if="showOrder">
				<el-table :data="orders" border style="width: 100%">
					<el-table-column prop="order_state" label="订单状态"></el-table-column>
					<el-table-column prop="order_time" label="订单时间"></el-table-column>
				</el-table>
			</el-card>
		</el-dialog>
	</div>
</template>
<script>
	import NP from 'number-precision'
	export default {
		props: {
			//标题
			titleName: {
				type: String,
				default: ''
			},
			//患者应诊信息
			seeInfo: {
				type: Object,
				default () {
					return {}
				}
			},
			//开方集合
			openRecipes: {
				type: Array,
				default () {
					return []
				}
			},
			//是否显示订单信息
			showOrder: {
				type: Boolean,
				default: false
			},
			//订单信息
			orders: {
				type: Array,
				default () {
					return []
				}
			},

		},
		data() {
			return {
				visible: false,
			}
		},
		created() {

		},
		mounted() {},
		methods: {
			init() {
				this.visible = true;
			},
			noClick() {
				this.visible = false;
				this.$emit('refreshTable');
			},
			getSummaries(param) {
				const {
					columns,
					data
				} = param;
				let sums = [];
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = '小计';
						return;
					}
					const values = data.map(item => Number(item[column.property]));
					if (!values.every(value => isNaN(value))) {
						sums[index] = values.reduce((prev, curr) => {
							prev = parseFloat(prev);
							if (!isNaN(prev)) {
								if (index == 3) { //克数不要小数
									return (Math.round(NP.plus(prev, curr) * 100) / 100)
								} else {
									return NP.plus(prev, curr);
								}

							} else {
								return prev
							}
						}, 0);
						if (index == 3) {
							sums[index] += '';
						} else {
							sums[index] += '';
						}
					} else {
						sums[index] = '';
					}
				});
				sums[4] = (Math.floor(sums[4] * 100) / 100).toFixed(2);
				return sums;
			},

		},
	}
</script>
<style scoped>
	.watchTableKey {
		display: inline-block;
		margin: 8px 10px;
		vertical-align: top;
	}

	.watchTableKey:first-of-type {
		text-align: left;
		width: 70px;
	}

	.watchTableValue {
		display: inline-block;
		margin: 8px 10px;
		vertical-align: top;
	}

	.watchTableValue:first-of-type {
		text-align: left;
		width: 120px;
	}

	.watchText {
		display: inline-block;
		margin: 8px 10px;
		width: 200px;
		vertical-align: top;
	}

	.watchText:first-of-type {
		text-align: right;
		width: 120px;
	}

	.watchTextLong {
		width: calc(100% - 200px);
		display: inline-block;
		margin: 8px 10px;
		vertical-align: top;

	}

	.mt30 {
		margin-top: 30px;
	}

	.box-b {
		width: calc(100% - 200px);
		display: inline-block;
		margin: 8px 30px;
		vertical-align: top;
		margin-left: 650px;
	}
</style>