<template>
  <div class="edit">
    <div id="editor"></div>
  </div>
</template>

<script>
// 引入 wangEditor
import wangEditor from 'wangeditor'

export default {

  props: {
    //标题
    htmlStr: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      editor: null,
    }
  },
  mounted() {
    const editor = new wangEditor(`#editor`)
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.$emit('input', newHtml);
    }
    editor.config.menus = [
      'head',
      'bold',
      'fontSize',
      'fontName',
      'italic',
      'underline',
      'strikeThrough',
      'indent',
      'lineHeight',
      'foreColor',
      // 'backColor',
      'link',
      'list',
      // 'todo',
      // 'justify',
      'quote',
      // 'emoticon',
      // 'image',
      // 'video',
      // 'table',
      // 'code',
      // 'splitLine',
      // 'undo',
      // 'redo',
    ]
    editor.config.colors = [
      "#000000",
      "#ffffff",
      "#eeece0",
      "#1c487f",
      "#4d80bf",
      "#c24f4a",
      "#8baa4a",
      "#7b5ba1",
      "#46acc8",
      "#f9963b",
      "#8C3C40FF",
    ]
    // 创建编辑器
    editor.create()
    this.editor = editor;
    this.editor.txt.html(this.htmlStr);
  },
  methods: {},
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy()
    this.editor = null
  }
}
</script>

<style lang="scss">
.edit {
  width: 100%;
  margin: auto;
  position: relative;
}
</style>