<template>
  <div class="table">
    <div class="container">
      <el-form :inline="true" :model="searchForm" ref="searchForm">
        <el-form-item label="药房名称">
          <el-input v-model.trim="searchForm.company_name" size="small" placeholder="请输入药房名称"></el-input>
        </el-form-item>
        <el-form-item label="剂型">
          <el-select v-model="searchForm.type" size="small" clearable placeholder="请选择药品剂型" class="selectWdith">
            <el-option v-for="item in drugTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌">
          <el-input v-model.trim="searchForm.brand" size="small" placeholder="请输入品牌"></el-input>
        </el-form-item>
        <el-form-item label="区域">
          <el-input v-model.trim="searchForm.area" size="small" placeholder="请输入区域"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchForm.is_use" size="small" placeholder="请选择状态" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="启用" value="0"></el-option>
            <el-option label="禁用" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
          <el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
          <el-button type="success" plain size="small" icon="el-icon-plus" @click="showAddVisible('add')">新增
          </el-button>
        </el-form-item>
      </el-form>

      <el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT" :data="tableData" border stripe size="mini" style="width: fit-content">
        <el-table-column type="index" label="序号" width="50" show-overflow-tooltip></el-table-column>
        <el-table-column prop="company_code" label="药房编码" width="70" show-overflow-tooltip></el-table-column>
        <el-table-column prop="company_name" label="药房名称" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="type" label="剂型" width="80" show-overflow-tooltip></el-table-column>
        <el-table-column prop="brand" label="品牌" width="80" show-overflow-tooltip></el-table-column>
        <el-table-column prop="company_img" label="LOGO" width="80" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-popover placement="right" title="" trigger="hover">
              <img :src="scope.row.company_img" width="160" height="160"/> <img slot="reference" :src="scope.row.company_img" :alt="scope.row.company_img" width="40" height="40"/>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column prop="area" label="区域" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="地址" width="260" show-overflow-tooltip></el-table-column>
        <el-table-column prop="legal_person" label="联系人" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="phone" label="联系电话" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="tjzx_name" label="所属调剂中心" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="140" show-overflow-tooltip></el-table-column>
        <el-table-column prop="create_user" label="创建人" width="80" show-overflow-tooltip></el-table-column>
        <el-table-column prop="is_use" label="是否启用" width="80" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_use" active-color="#13ce66" inactive-color="#ff4949" active-value="0" inactive-value="1" @change="changeState(scope.row.is_use, scope.row.id)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" width="285">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" icon="el-icon-edit" plain @click="showAddVisible('edit', scope.row)" :loading="scope.row.editLoading">编辑</el-button>
            <el-button size="mini" type="danger" plain icon="el-icon-delete" @click="deleteStation(scope.row)">删除</el-button>
            <el-button size="mini" type="warning" :disabled="scope.row.account == 'admin'" icon="el-icon-refresh" plain @click="resetPwd(scope.row)" v-hasPermi="['sys:user:upd']">重置密码</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--TODO 分页-->
      <div class="pagination table-width-b" v-if="totalPage != 0" :style="'width:' + footWidth + ';max-width:100%;'">
        <el-pagination @size-change="handleSizeChange" :page-sizes="[10, 20, 50]" :page-size="searchForm.limit" :current-page.sync="searchForm.currentPage" @current-change="handleCurrentChange" layout="jumper, prev, pager, next, sizes, total" :total="total"></el-pagination>
      </div>
      <!--新增/修改弹框-->
      <el-dialog :close-on-click-modal="false" :title="titleName" v-if="addVisible" :visible.sync="addVisible" width="700px" top="20vh" :append-to-body="true" :before-close="noClick">
        <el-form :inline="true" ref="addForm" :model="addForm" label-width="100px" :rules="addFormRules">
          <el-form-item label="药房编码" prop="company_code">
            <el-input v-model.trim="addForm.company_code" size="small" placeholder="请输入药房编码"></el-input>
          </el-form-item>
          <el-form-item label="药房名称" prop="company_name">
            <el-input v-model.trim="addForm.company_name" size="small" placeholder="请输入药房名称"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="legal_person">
            <el-input v-model.trim="addForm.legal_person" size="small" placeholder="请输入联系人"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model.trim="addForm.phone" size="small" placeholder="请输入联系电话"></el-input>
          </el-form-item>

          <el-form-item label="剂型" prop="type">
            <el-select v-model="addForm.type" size="small" clearable placeholder="请选择药品剂型" class="selectWdith">
              <el-option v-for="item in drugTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品牌" prop="brand" :rules="{
              required: true,
              message: ' 品牌不能为空',
              trigger: 'blur',
            }">
            <el-input v-model.trim="addForm.brand" size="small" placeholder="请输入品牌"></el-input>
          </el-form-item>
          <el-form-item label="区域" prop="area" :rules="{
              required: true,
              message: ' 区域不能为空',
              trigger: 'blur',
            }">
            <el-input style="width: 200px" maxlength="200" size="small" placeholder="请输入区域" v-model="addForm.area"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address" :rules="{
              required: true,
              message: '地址不能为空',
              trigger: 'blur',
            }">
            <el-input style="width: 200px" maxlength="200" size="small" placeholder="请输入地址" v-model="addForm.address"></el-input>
          </el-form-item>
          <el-form-item label="调剂中心" prop="tjzx_id" :rules="{
              required: true,
              message: ' 调剂中心不能为空',
              trigger: 'blur',
            }">
            <el-select v-model="addForm.tjzx_id" size="small" clearable placeholder="请选择调剂中心" class="selectWdith">
              <el-option v-for="item in tjzxOptions" :key="item.id" :label="item.tjzx_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发货信息" prop="fhxx">
            <div style="width: 510px;">
              <wEditer v-model="addForm.fhxx" :htmlStr="addForm.fhxx"></wEditer>
            </div>
          </el-form-item>
          <el-form-item label="药房LOGO" prop="company_img">
            <div style="width: 510px">
              <el-upload action="#" :class="{ hideUpload: hideUpload }" :on-change="uploadImgChange" list-type="picture-card" :file-list="addForm.fileList" :on-remove="uploadImgRemove" :auto-upload="false">
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div style="color: red">
              *照片格式要求为.jpg、.png或.jpeg，大小是2M以内
            </div>
          </el-form-item>

          <el-form-item label="备注" prop="remark">
            <el-input style="width: 400px;" v-model.trim="addForm.remark" size="small" placeholder="请输入备注"></el-input>
          </el-form-item>

        </el-form>
        <span slot="footer" class="dialog-footer">
					<el-button size="small" @click="noClick()">取&nbsp;消</el-button>
					<el-button type="primary" size="small" @click="saveEdit('addForm', type)">保&nbsp;存</el-button>
				</span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import BaseUrl from "@/utils/baseUrl";
import wEditer from '@/components/editor.vue'

export default {
  created() {
    this.getData();
  },
  components: {
    wEditer
  },
  data() {
    return {
      footWidth: "0px",
      loading: true, //列表loading
      tableData: [],
      searchForm: {
        company_name: "",
        limit: limit,
        currentPage: 1,
        type: "", // 剂型
        brand: "", // 品牌
        area: "", // 区域
        is_use: "0", // 状态，默认启用
      },
      total: 0,
      totalPage: 0,
      titleName: "",
      addVisible: false,
      addForm: {},
      addFormRules: {
        company_code: [{
          required: true,
          message: "请输入药房编码",
          trigger: "blur",
        },],
        company_name: [{
          required: true,
          message: "请输入药房名称",
          trigger: "blur",
        },],
        legal_person: [{
          required: true,
          message: "请输入联系人",
          trigger: "blur",
        },],
        phone: [{
          required: true,
          message: "请输入联系电话",
          trigger: "blur",
        },
          {
            validator: function (rule, value, callback) {
              if (
                /^(13[0-9]|14[0-9]|15[0-9]|16[6]|18[0-9]|19[6,9]|17[0-9])\d{8}$/i.test(
                  value
                ) == false
              ) {
                callback(new Error("请输入正确的联系电话"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      type: "", //add,edit
      row: null,
      companyOptions: [],
      hospitalOptions: [],
      delFileName: "", //删除图片的名称
      hideUpload: false,
      drugTypeOptions: [],
      tjzxOptions: []
    };
  },
  mounted() {
    //this.getTableProp();
    var that = this;
    document.getElementsByClassName("content")[0].onclick = function () {
      if (that.currentChange) {
        that.currentChange = false;
      } else {
        that.setCurrent();
      }
    };
    that.footWidth = that.$refs.table.bodyWidth;
    this.getDosageForm()

  },
  methods: {
    //获取剂型
    getDosageForm() {
      this.$http.post(BaseUrl.getDrugTypePageList, {}).then((res) => {
        if (res.data.code == 0) {
          this.drugTypeOptions = [];
          for (let i = 0; i < res.data.data.length; i++) {
            var obj = {
              label: "",
              value: "",
            }; //用于接收被改变的对象
            obj.label = res.data.data[i].type_name;
            obj.value = res.data.data[i].type_name;
            this.drugTypeOptions.push(obj);
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    setCurrent(row) {
      //取消单选
      //this.$refs.table.setCurrentRow(row);
    },
    handleCurrentChangeT(row, index) {
      this.currentChange = true;
      this.$refs.table.setCurrentRow(row);
    },
    getData() {
      this.loading = true; //列表loading
      this.$http.post(BaseUrl.getCompanyPageList, this.searchForm).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.data;
          this.totalPage = res.data.data.totalPage;
          this.total = res.data.data.total;
          this.loading = false; //列表loading
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    handleSizeChange(val) {
      this.searchForm.limit = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.searchForm.currentPage = val;
      this.getData();
    },
    reset() {
      this.resetSearchForm(this.searchForm, [{
        k: "limit",
        v: this.searchForm.limit,
      },
        {
          k: "currentPage",
          v: this.searchForm.currentPage,
        },
      ]);
    },
    showAddVisible(type, row) {
      this.type = type;
      this.hideUpload = false;
      this.getDosageForm();
      this.getTjzxSelect()
      if (type == "add") {
        this.titleName = "新增药房";
        this.addForm = {
          company_name: "",
          legal_person: "",
          phone: "",
          address: "",
          tjzx_id: "",
          fileList: [],
          fhxx:''

        };
        console.log("新增fileList: []" + this.addForm.fileList);
        this.addVisible = true;
      } else {
        this.titleName = "修改药房";
        this.row = row;
        this.$set(row, "editLoading", true);
        this.$http.post(BaseUrl.getCompanyById, {
          id: row.id,
        }).then((res) => {
          console.log("res: ", res);
          if (res.data.code == 0) {
            this.addForm = res.data.data;
            this.addForm.fileList = [];
            if (this.addForm.company_img) {
              var name = this.addForm.company_img.split("witmed/")[1];
              console.log("name: ", name);
              this.addForm.fileList.push({
                name: name,
                url: this.addForm.company_img,
              });
              console.log("修改有回显fileList: []" + this.addForm.fileList);
              this.delFileName = name; //给删除的图片命名
              console.log("this.delFileName: ", this.delFileName);
              this.hideUpload = true;
            } else {
              console.log("修改无回显fileList: []" + this.addForm.fileList);
            }
            this.addVisible = true;
            this.$set(row, "editLoading", false);
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      }
    },
    //获取调剂中心下拉
    getTjzxSelect(){
      this.$http.post(BaseUrl.getTjzxPageList, {
        currentPage: 1,
        limit: 999999,
      }).then(res => {
        this.tjzxOptions = res.data.data.data;
      });
    },
    //保存新增/修改
    saveEdit(form, type) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (type == "add") {
            this.$http.post(BaseUrl.addCompanyInfo, this[form]).then((res) => {
              if (res.data.code == 0) {
                this.getData();
                this.addVisible = false;
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
          } else {
            this[form].id = this.row.id;
            this.$http.post(BaseUrl.updCompanyById, this[form]).then((res) => {
              if (res.data.code == 0) {
                this.getData();
                this.addVisible = false;
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
          }
        }
      });
    },
    deleteStation(row) {
      this.$confirm(
        '是否确认删除药房为"' + row.company_name + '"的数据项?',
        "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$http.post(BaseUrl.delCompany, {
          id: row.id,
        }).then((res) => {
          if (res.data.code == 0) {
            this.getData();
            this.$message({
              message: "删除成功",
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    noClick() {
      this.getData();
      this.addVisible = false;
    },
    //上传图片upLoadComLogoImg/delComLogoImg
    uploadImgChange(file, fileList) {
      let that = this;
      console.log(file);
      console.log(fileList);
      const isJPG =
        file.raw.type === "image/jpeg" ||
        file.raw.type === "image/png" ||
        file.raw.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        fileList.splice(fileList.length - 1, 1);
        return false;
      }
      if (!isJPG) {
        this.$message.error("上传图片只能是jpeg或PNG 格式!");
        fileList.splice(fileList.length - 1, 1);
        return false;
      }
      let fileName = file.name.lastIndexOf("."); //取到文件名开始到最后一个点的长度
      let fileNameLength = file.name.length; //取到文件名长度
      let fileFormat = file.name.substring(fileName + 1, fileNameLength); //截
      file.name = new Date().getTime() + "." + fileFormat;
      this.delFileName = file.name;
      var newfile = new File([file.raw], file.name, {
        type: file.raw.type,
      });
      this.hideUpload = fileList.length >= 1;
      this.saveImg(newfile, "img");
    },

    resetPwd(row) {
      this.$confirm(
        "是否对" + row.company_name + "的账号进行重置密码",
        "重置密码", {
          distinguishCancelAndClose: true,
          confirmButtonText: "确定重置",
          cancelButtonText: "取消",
          center: true,
        }
      ).then(() => {
        var data = {
          type: "1",
          id: row.id
        };
        this.$http.post(BaseUrl.restCompanyPassWord, data).then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.getData();
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      }).catch((action) => {
      });
    },
    //上传图片
    saveImg(file, type) {
      console.log(type);
      let that = this;
      var fd = new FormData();
      var f = file;
      fd.append("file", f);
      // this.addForm.fileList=[];
      this.$axios.post(BaseUrl.upLoadComLogoImg, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        if (res.data.code == 0) {
          that.addForm.company_img = res.data.data;
          this.$refs["addForm"].clearValidate(["company_img"]);
        } else {
          this.addForm.fileList = [];
          that.hideUpload = false;
          that.addForm.company_img = "";
          return false;
        }
      }).catch((error) => {
        this.addForm.fileList = [];
        that.hideUpload = false;
        that.addForm.company_img = "";
      });
    },
    //删除图片
    uploadImgRemove(file) {
      let that = this;
      this.$http.post(BaseUrl.delComLogoImg, {
        fileName: this.delFileName,
      }).then((res) => {
        console.log("res: ", res);
        if (res.data.code == 0) {
          this.addForm.fileList = [];
          that.hideUpload = false;
          that.addForm.company_img = "";
        } else {
          debugger;
          this.addForm.fileList = [];
          that.hideUpload = false;
          that.addForm.company_img = "";
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      }).catch((error) => {
        this.addForm.fileList = [];
        that.hideUpload = false;
      });
    },
    changeState(state, id) {
      this.loading = true;
      this.$http.post(BaseUrl.updCompanyIsUseById, {
        id: id,
        is_use: state,
      }).then((res) => {
        if (res.data.code == 0) {
          if (state == 0) {
            this.$message({
              message: "启用成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "停用成功",
              type: "success",
            });
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
        this.getData();
      }).catch((error) => {
        this.$message({
          message: res.data.msg,
          type: "warning",
        });
        this.getData();
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.selectWdith {
  width: 200px;
}

.hideUpload .el-upload--picture-card {
  display: none;
}
</style>