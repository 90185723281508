const BaseUrl = {
  //登录获取信息
  login: `/login`,
  //退出登录
  logout: `/logout`,
  //登录获取初始化菜单
  initMenu: `/menu/nav`,
  perms: `/menu/perms`,
  //===========================首页========================//

  //================================账号管理==============================//
  //获取用户管理列表
  getUserListPage: `/user/getUserListPage`,
  //新增用户
  addUserInfo: `/user/addUserInfo`,
  //根据id获取用户详细信息
  getUserById: `/user/getUserById`,
  //修改保存用户信息
  saveUserInfo: `/user/saveUserInfo`,
  //删除用户信息
  deleteUser: `/user/deleteUser`,
  //重置密码
  resetPassword: `/user/resetPassword`,
  //启用/停用状态修改
  updateAccountState: `/user/updateAccountState`,
  //修改登录账号密码
  updatePassword: `/user/updatePassword`,
  //获取角色信息的下拉菜单
  queryRoleByStr: '/user/queryRoleByStr',
  //================================医院管理===============================//
  //分页查询医院信息列表
  getHospitalPageList: '/hospital/getHospitalPageList',
  //根据id查询医院信息
  getHospitalById: '/hospital/getHospitalById',
  //新增医院信息
  addHospitalInfo: '/hospital/addHospitalInfo',
  //编辑医院信息
  saveHospitalById: '/hospital/saveHospitalById',
  //删除医院信息
  delHospital: '/hospital/delHospital',
  //医院名称下拉
  getHospitalSelect: '/hospital/getHospitalSelect',
  //根据地区查询医院列表
  getHospitalSelectByArea: '/hospital/getHospitalSelectByArea',
  //医院等级下拉
  getLevelSelect: '/hospital/getLevelSelect',
  //患者信息list
  getPatientPageList: '/patient/getPatientPageList',
  //药房管理是否启用
  updCompanyIsUseById: 'company/updCompanyIsUseById',

  //================================科室管理==============================//
  //分页查询科室信息列表 /
  getDeptPageList: '/dept/getDeptPageList',
  //根据id查询科室信息
  getDeptById: '/dept/getDeptById',
  //新增科室信息
  addDeptInfo: '/dept/addDeptInfo',
  //编辑科室信息
  saveDeptById: '/dept/saveDeptById',
  //删除科室信息
  delDept: '/dept/delDept',
  //科室信息下拉
  getDeptSelect: '/dept/getDeptSelect',
  //科室信息下拉
  getDept: '/dept/getDept',
  //================================医生管理==============================//
  //查询列表信息
  getDoctorPageLimit: '/doctor/getDoctorPageLimit',
  //根据id查单个医生信息
  getDoctorInfo: '/doctor/getDoctorInfo',
  //校验手机号是否重复
  checkDoctorPhone: '/doctor/checkDoctorPhone',
  //新增医生信息
  insertDoctor: '/doctor/insertDoctor',
  //修改医生信息
  updateDoctor: '/doctor/updateDoctor',
  //删除医生信息
  deleteDoctor: '/doctor/deleteDoctor',
  //获取推荐人下拉选
  getReferrerList: `/referrer/getReferrerList`,
  //医生信息审核接口
  isAuditDoctor: '/doctor/isAuditDoctor',
  //审核话术下拉列表
  getAuditSelect: '/doctor/getAuditSelect',
  //查询医生提现设置分页列表信息
  getDoctorRatioPageLimit: '/doctor/getDoctorRatioPageLimit',
  //新增医生个性化比例设置
  updDoctorRatio: '/doctor/updDoctorRatio',
  getGLCompanySelect: 'doctorCompany/getCompanySelect',//获取医生管理药房列表
  insertDoctorCompany: 'doctorCompany/insertDoctorCompany',//新增管理药房（新增删除都是直接覆盖）
  getDoctorCompanyListByDoctorId: 'doctorCompany/getDoctorCompanyListByDoctorId',//返显，传入参数 doctor_id
  //================================应诊管理==============================//
  getDoctorSeePageLimit: '/see/getDoctorSeePageList',
  getSeeRecordBySeeId: '/see/getSeeRecordBySeeId',
  //================================服务费管理==============================//
  getItemPageList: '/item/getItemPageList',
  getItemById: '/item/getItemById',
  addItemInfo: '/item/addItemInfo',
  saveItemById: '/item/saveItemById',
  delItem: '/item/delItem',

  //================================药品管理==============================//
  //查询药品分页列表信息
  getDrugPageList: `/drug/getDrugPageList`,
  //根据id查询药品基本信息
  getDrugById: `/drug/getDrugById`,
  //新增药品信息
  addDrugInfo: `/drug/addDrugInfo`,
  //修改药品信息
  saveDrugById: `/drug/saveDrugById`,
  //删除药品信息
  delDrug: `/drug/delDrug`,
  //药品下拉选
  getDrugSelect: `/drug/getDrugDictSelect`,
  //厂家下拉
  getCompanySelect: `/drug/getCompanySelect`,
  //药品启用/禁用
  updDrugUseFlag: `/drug/updDrugUseFlag`,
  //药品信息导出
  drugExport: '/drug/drugExport',
  //--------------------------药品类别-----------------
  getDrugTypePageList: '/drugType/getDrugTypePageList', //查询药品类型分页列表信息 没有分页
  addDrugType: '/drugType/addDrugType', //药品类型新增
  delDrugType: '/drugType/delDrugType', //药品类型删除
  //--------------------------药房管理-----------------
  getCompanyPageList: '/company/getCompanyPageList', //查询药房管理分页列表信息
  getCompanyById: '/company/getCompanyById', //根据id查询药房基本信息
  addCompanyInfo: '/company/addCompanyInfo', //药房信息新增
  updCompanyById: '/company/updCompanyById', // 药房信息修改
  delCompany: '/company/delCompany', //药房信息删除
  upLoadComLogoImg: '/doctor/uploadAvatarUrl', //药房logo上传
  delComLogoImg: '/company/delCompanyImg', //药房logo删除

  restCompanyPassWord: '/company/resetPassword',
  //================================处方管理==============================//
  //查询处方模板的分页列表
  getRecipePageList: `/recipe/getRecipePageList`,
  //根据id查询处方模板信息
  getRecipeById: `/recipe/getRecipeById`,
  //新增处方模板
  addRecipeInfo: `/recipe/addRecipeInfo`,
  //修改处方模板
  saveRecipeById: `/recipe/saveRecipeById`,
  //删除处方模板
  delRecipe: `/recipe/delRecipe`,
  //根据处方id查询处方详情
  getRecipeDetailById: `/recipe/getRecipeDetailById`,
  //添加处方明细中药品信息
  addRecipeDetail: `/recipe/addRecipeDetail`,
  //批量保存处方明细中药品信息
  saveRecipeDetails: `/recipe/saveRecipeDetails`,
  //删除处方明细中药品信息
  delRecipeDetail: `/recipe/delRecipeDetail`,
  //获取处方下拉选
  getRecipeSelect: `/recipe/getRecipeSelect`,
  //=============================中医证型============================//
  //中医证型模板分页列表查询
  getDiagPageList: `/diag/getDiagPageList`,
  //根据id查询中医证型模板
  getDiagById: `/diag/getDiagById`,
  //新增中医证型模板
  addDiagInfo: `/diag/addDiagInfo`,
  //编辑中医证型模板
  saveDiagById: `/diag/saveDiagById`,
  //删除中医证型模板
  delDiag: `/diag/delDiag`,
  //================================订单管理==============================//
  //查询订单分页列表
  getOrderPageList: `/order/getOrderPageList`,
  //添加发货信息
  saveOrderById: `/order/saveOrderById`,
  //根据seeid查询订单明细
  getOrderBySeeId: `/order/getOrderBySeeId`,

  //取消订单，修改订单为关闭状态
  cancelOrderById: `/order/updOrderState`,

  //订单状态下拉
  orderTypeList: `/order/orderTypeList`,
  //订单打印信息
  printOrderInfo: `/order/printOrderInfo`,
  //待发货
  getOrderPageListOfWait: '/order/getOrderPageListOfWait',
  //待收货
  getOrderPageListOfSend: '/order/getOrderPageListOfSend',
  //已签收
  getOrderPageListOfSign: '/order/getOrderPageListOfSign',
  //获取未发货总数
  getOrderListCountOfWait: '/order/getOrderListCountOfWait',
  //修改订单打印状态
  updOrderPrintState: '/order/updOrderPrintState',
  //订单分发列表
  getOrderPageListSend: '/order/getOrderPageListSend',
  //订单来源
  orderDictSelect: '/order/orderDictSelect',
  //根据id查询订单分发信息
  getOrderGetherById: '/order/getOrderGetherById',
  //订单下发
  sendPrintOrder: '/order/sendPrintOrder',
  //获取打印参数：requestID accessToken templateCode
  returnParam: '/order/returnParam',
  //获取顺丰运单号
  sendSFOrderByNum: '/order/sendSFOrderByNum',
  //获取物流信息
  getOrderByWaybillNo: '/order/getOrderByWaybillNo',
  //获取顾客编码
  getPartnerID: '/order/getPartnerID',
  //================================财务分析==============//
  //交易统计月份列表查询
  getYearsStatisList: '/statis/getYearsStatisList',
  //交易统计按天数查询
  getDayStatisList: '/statis/getDayStatisList',
  //年份下拉列表
  getYearsSelect: '/statis/getYearsSelect',
  //财务订单
  getFinaOrderOfPage: `/finaOrder/getFinaOrderOfPage`,
  //财务订单导出
  finaOrderExport: `/finaOrder/export`,
  //========================营销管理=====================//
  //查询业务员提现设置分页列表信息
  getCashoutPageList: '/cashout/getCashoutPageList',
  //新增提现设置信息
  addCashoutInfo: '/cashout/addCashoutInfo',
  //查询业务员资料分页列表信息
  getSalesmanPageList: '/sales/getSalesmanPageList',
  //新增业务员资料信息
  addSalesmanInfo: '/sales/addSalesmanInfo',
  //根据id查询业务员资料信息
  getSalesmanById: '/sales/getSalesmanById',
  //编辑业务员资料信息
  updSalesmanById: '/sales/updSalesmanById',
  //删除业务员资料信息
  delSalesman: '/sales/delSalesman',
  //审核业务员资料信息
  updAudit: '/sales/updAudit',
  //头像删除
  delAvatarUrl: '/sales/delAvatarUrl',
  //上传头像
  uploadAvatarUrl: '/doctor/uploadAvatarUrl',
  //根据id查询提现设置信息
  getCashoutById: '/cashout/getCashoutById',
  //编辑提现设置信息
  updCashoutById: '/cashout/updCashoutById',
  //删除提现设置信息
  delCashout: '/cashout/delCashout',
  //推广明细
  getSalesmanListByAncestors: '/sales/getSalesmanListByAncestors',
  //邀请明细
  getDoctorListBySaleId: '/sales/getDoctorListBySaleId',

  //佣金核销列表
  getBrokeraPageList: '/brokera/getBrokeraPageList',
  //分页核销记录
  getSalesRecordPageList: '/brokera/getSalesRecordPageList',
  getSalesmanList: '/sales/getSalesmanList',
  //新增核销记录
  addBrokeraInfo: '/brokera/addBrokeraInfo',
  //查询剩余金额
  getBrokeraById: '/brokera/getBrokeraById',


  //提现列表
  getDoctCashoutPageList: '/doctCashOut/getDoctCashoutPageList',
  //查看详情
  getDoctCashoutById: '/doctCashOut/getDoctCashoutById',
  //修改
  updDoctCashoutById: '/doctCashOut/updDoctCashoutById',

  getDoctorTranById: '/doctor/getDoctorTranById',
  //=========================系统维护====================//
  //新增角色时全部角色列表
  getMenuList: `/menu/getMenuList`,
  //角色管理
  //获取列表数据接口
  getRoleByPage: `/role/getRoleByPage`,
  //删除角色
  deleteRole: `/role/deleteRole`,
  //获取树形结构数据
  getMenuByRoleType: `/role/getMenuByRoleType`,
  //修改保存接口
  updateMenuRole: `/role/updateMenuRole`,
  //新增保存接口
  saveRole: `/role/saveRole`,
  //点击修改获取详情
  getRoleById: `/role/getRoleById`,
  //=========================系统配置====================//
  //新增系统配置信息
  addConfigInfo: `/addConfigInfo`,
  //查询系统配置信息
  getConfig: `/getConfig`,
  //新增组织机构
  addDept: '/dept/addDept',
  //根据id获取组织机构详情
  queryDeptInfoById: '/dept/queryDeptInfoById',
  //编辑组织机构
  updateDept: '/dept/updateDept',
  //根据id删除组织机构（更新删除标记）
  deleteDeptById: '/dept/deleteDeptById',
  //log日志列表
  getLogListPage: '/log/getLogListPage',
  //=======================仲景学苑===========//
  //查询栏目管理的分页列表
  getColumnPageList: '/column/getColumnPageList',
  //新增栏目管理
  addColumnInfo: '/column/addColumnInfo',
  //根据id查询栏目管理
  getColumnById: '/column/getColumnById',
  //修改栏目管理内容
  updColumnById: '/column/updColumnById',
  //删除栏目管理
  delColumn: '/column/delColumn',
  //上传图片/视频
  uploadImg: '/column/uploadImg',
  //图片/视频删除
  delImg: '/column/delImg',
  //二级栏目下拉
  getSecColuSelect: '/column/getSecColuSelect',
  //一级栏目下拉
  getFriColuSelect: '/column/getFriColuSelect',
  //查询内容管理的分页列表
  getContentPageList: '/content/getContentPageList',
  //新增内容管理
  addContentInfo: '/content/addContentInfo',
  //根据id查询内容管理
  getContentById: '/content/getContentById',
  //修改内容管理内容
  updContentById: '/content/updContentById',
  //删除内容管理
  delContent: '/content/delContent',
  //修改内容管理状态/上架下架
  updContentState: '/content/updContentState',
  //修改轮播图是否展示
  updSlides: '/content/updSlides',
  //是否展示首页
  updIndexShow: '/content/updIndexShow',
  //=======================商城管理===========//
  // -------------------商品管理------------------
  // 查询商品管理分页列表
  getProductPageList: '/product/getProductPageList',
  // 根据id查询商品基本信息
  getProductById: '/product/getProductById',
  // 新增商品信息
  addProductInfo: '/product/addProductInfo',
  // 修改商品信息
  updProductById: '/product/updProductById',
  // 删除商品信息
  delProduct: '/product/delProduct',
  // 修改上架/下架（0 下架  1 上架）
  updStateById: '/product/updStateById',
  // 图片上传
  productUploadImg: '/product/uploadImg',
  // 图片删除
  productDelImg: '/product/delImg',
  //-----------------订单管理-----------------------
  getProductOrderPageList: '/product/getProductOrderPageList', //查询商品订单信息分页列表信息
  updProductOrderById: '/product/updProductOrderById', //商品订单发货
  //-----------------兑换统计-----------------------
  getExchangeRec: '/exchange/getExchangeRec', //查询兑换统计信息
  //-----------导出------------
  doctorExport: '/doctor/export', //导出医生信息
  allOrderExport: '/order/exportAll', //导出所有订单
  beforeOrderExport: '/order/exportWait', // 导出待发货订单
  afterOrderExport: '/order/exportSend', // 导出已发货订单
  enterOrderExport: '/order/exportSign', // 导出已签收订单
  busInfoExport: '/sales/exportSales', // 导出业务人员资料
  doctorJDetailExport: '/doctor/exportDoctorTran', // 导出医生积分-积分明细
  busJDetailExport: '/brokera/exportSalesRecord', // 导出业务员积分-积分明细
  patientInfoExport: '/patient/export', // 导出患者信息
  getAdvicePageList: '/advice/getAdvicePageList',// 查询模板列表
  getAdviceById: '/advice/getAdviceById',	// 根据ID查询
  addAdvice: '/advice/addAdvice',	// 新增
  delAdviceById: '/advice/delAdviceById',	// 删除
  updAdviceById: '/advice/updAdviceById',	// 修改
  getAdviceDetailList: '/advice/getAdviceDetailList',// 查询模板详情列表
  addAdviceDetail: '/advice/addAdviceDetail', //新增明细
  delAdviceDetailById: '/advice/delAdviceDetailById',// 删除明细
  updAdviceDetailById: '/advice/updAdviceDetailById',// 修改明细

  getOrderStatDoctorList: '/orderStat/getOrderStatDoctorList', //按医生统计
  getOrderStatCompanyList: '/orderStat/getOrderStatCompanyList', //按药房统计
  getOrderStatTypeList: 'orderStat/getOrderStatTypeList',//按剂型统计
  etOrderStatSalesList: '/orderStat/getOrderStatSalesList',//按业务员统计
  getOrderStatDoctorZjList: '/orderStat/getOrderStatDoctorZjList',//医生诊金统计

  getOrderStatDoctorListExcel: '/orderStat/getOrderStatDoctorListExcel', //按医生统计导出
  getOrderStatCompanyListExcel: '/orderStat/getOrderStatCompanyListExcel', //按药房统计导出
  getOrderStatTypeListExcel: 'orderStat/getOrderStatTypeListExcel',//按剂型统计导出
  getOrderStatSalesListExcel: '/orderStat/getOrderStatSalesListExcel',//按业务员统计导出
  getOrderStatDoctorZjListExcel: '/orderStat/getOrderStatDoctorZjListExcel',//医生诊金统计导出


  getTjzxPageList: '/tjzx/getTjzxPageList',//  查询列表，currentPage  limit   tjzx_name 三个参数李超
  getTjzxById:'/tjzx/getTjzxById' ,// 根据ID查询   参数：id
  addTjzx:'/tjzx/addTjzx',// 新增调剂中心
  delTjzxById:'/tjzx/delTjzxById',// 删除调剂中心,参数id
  updTjzxById:'/tjzx/updTjzxById',// 修改调剂中心,参数id
  resetTjzxPassword:'/tjzx/resetTjzxPassword',// 重置密码， 参数id
  saveCert:'/doctor/saveCert',// 保存证件信息(图片）
  delCert:'/doctor/delCert'// 保存证件信息(图片）
}
export default BaseUrl;
